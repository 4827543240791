<template>
    <el-main>
        <el-form class="el-form-search" label-width="110px">
            <el-form-item label="订单号：">
                <el-input size="small" v-model="searchForm.logisticID"></el-input>
            </el-form-item>
            <el-form-item label="支付状态：">
                <el-select v-model="searchForm.pay_status" size="small" placeholder="请选择">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="未付款" :value="1"></el-option>
                    <el-option label="已支付" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="运单状态：">
                <el-select v-model="searchForm.status" size="small" placeholder="请选择">
                    <el-option v-for="item in status" :key="item" :label="item" :value="item"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="快递名称：">
                <el-input size="small" v-model="searchForm.express_name"></el-input>
            </el-form-item>
            <el-form-item label="同步时间：">
                <el-date-picker size="small" v-model="time" type="daterange" range-separator="至" start-placeholder="开始日期"
                    end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="运营商名称：">
                <el-input size="small" v-model="searchForm.facilitator_name"></el-input>
            </el-form-item>
            <el-form-item label="运营商电话：">
                <el-input size="small" v-model="searchForm.facilitator_mobile"></el-input>
            </el-form-item>
            <el-form-item label="商家名称：">
                <el-input size="small" v-model="searchForm.store_name"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="store_name" label="店铺名称" align="center" min-width="120"></el-table-column>
            <el-table-column prop="order_sn" label="用户类型" align="center" min-width="90">
                <template v-slot="{ row }">{{ row.user_type == 1 ? 'H5' : '小程序' }}</template>
            </el-table-column>
            <el-table-column prop="order_title" label="用户信息" align="center" min-width="200">
                <template v-slot="{ row }">
                    <div style="display: flex;">
                        <el-image :src="row.headimgurl"
                            style="border-radius: 50%;width: 50px; height: 50px;margin-right: 10px;"></el-image>
                        <div>{{ row.nickname || '--' }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="facilitator_name" label="服务商姓名" align="center" min-width="120"></el-table-column>
            <el-table-column prop="facilitator_mobile" label="服务商电话" align="center" min-width="120"></el-table-column>
            <el-table-column prop="express_name" label="快递公司" align="center" min-width="100"></el-table-column>
            <el-table-column prop="mobile" label="寄件人手机号" align="center" min-width="110"></el-table-column>
            <el-table-column label="寄件地址" align="center" min-width="200">
                <template v-slot="{ row }">{{ row.sender_province + row.sender_city + row.sender_area }}</template>
            </el-table-column>
            <el-table-column label="收件地址" align="center" min-width="200">
                <template v-slot="{ row }">{{ row.recipients_province + row.recipients_city + row.recipients_area
                }}</template>
            </el-table-column>
            <el-table-column prop="logisticID" label="单号" align="center" min-width="180"></el-table-column>
            <el-table-column label="支付状态" align="center" min-width="90">
                <template v-slot="{ row }">{{ row.pay_status == 1 ? '未支付' : '已支付' }}</template>
            </el-table-column>
            <el-table-column prop="status" label="运单状态" align="center" min-width="90"></el-table-column>
            <el-table-column prop="sender_money" label="预估费用" align="center" min-width="110">
                <template v-slot="{ row }">￥{{ row.sender_money }}</template>
            </el-table-column>
            <el-table-column prop="pay_money" label="下单实际运费金额" align="center" min-width="150">
                <template v-slot="{ row }">￥{{ row.pay_money }}</template>
            </el-table-column>
            <el-table-column prop="insurancePrice" label="保价费用" align="center" min-width="110">
                <template v-slot="{ row }">￥{{ row.insurancePrice }}</template>
            </el-table-column>
            <el-table-column prop="coupon_pmt" label="优惠券/红包额度" align="center" min-width="130">
                <template v-slot="{ row }">￥{{ row.coupon_pmt }}</template>
            </el-table-column>
            <el-table-column prop="refundprofit" label="需扣除的返利" align="center" min-width="110"></el-table-column>
            <el-table-column prop="orders_time" label="下单时间" align="center" min-width="180"></el-table-column>
        </el-table>
        <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
        </Paging>
        <el-dialog title="手动分佣" :visible.sync="showSetCommission" width="650px">
            <el-form ref="form" label-width="90px">
                <el-form-item label="sn">
                    <el-input v-model="sn"></el-input>
                </el-form-item>
                <el-form-item label="类型：" v-if="SetCommission_type == 1">
                    <el-select v-model="product_type" placeholder="请选择">
                        <el-option label="话费" :value="10"></el-option>
                        <el-option label="直充" :value="20"></el-option>
                        <el-option label="吃喝卡券" :value="30"></el-option>
                        <el-option label="电费" :value="40"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showSetCommission = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmSetCommission">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="手动激励" :visible.sync="showEncourage" width="650px">
            <el-form ref="form" label-width="90px">
                <el-form-item label="sn">
                    <el-input v-model="sn"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showEncourage = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmEncourage">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>
  
<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import _ from 'lodash';
export default {
    components: {
        Paging,
    },
    data () {
        return {
            searchForm: {
                page: 1,
                rows: 10,
                logisticID: '',
                pay_status: 0,
                express_name: '',
                status: '',
                start_time: '',
                end_time: '',
                facilitator_name: '',
                facilitator_mobile: '',
                store_name: ''
            },
            status: [],
            time: '',
            list: [],
            total_number: 0,
            category: [],
            showSetCommission: !1,
            showEncourage: !1,
            sn: '',
            product_type: '',
            SetCommission_type: 1,
        };
    },
    created () {
        this.getStatus()
        this.getList();
    },
    methods: {
        getStatus () {
            this.$axios.post(this.$api.serviceProvider.KuailonOrdersGetStatus).then(res => {
                if (res.code == 0) {
                    this.status = res.result
                }
            });
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
            } else {
                this.searchForm.page = val;
            }
            this.getList();
        },
        search () {
            this.searchForm.page = 1;
            this.getList();
        },
        clearSearch () {
            this.searchForm = {
                page: 1,
                rows: 10,
                logisticID: '',
                pay_status: 0,
                express_name: '',
                status: '',
                start_time: '',
                end_time: '',
                facilitator_name: '',
                facilitator_mobile: '',
                store_name: ''
            };
            this.getList();
        },
        setCommission (id, type) {
            this.showSetCommission = !0;
            this.id = id;
            this.sn = '';
            this.product_type = '';
            this.SetCommission_type = type;
        },
        setEncourage (id) {
            this.showEncourage = !0;
            this.id = id;
            this.sn = '';
        },
        comfirmEncourage () {
            if (!this.sn) return this.$message.warning('请填写sn');
            this.$axios
                .post(this.$api.serviceProvider.Encourage, {
                    id: this.id,
                    sn: this.sn,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('处理成功');
                        this.showEncourage = !1;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        comfirmSetCommission () {
            if (!this.sn) return this.$message.warning('请填写sn');
            if (!this.product_type && this.SetCommission_type == 1) return this.$message.warning('请选择类型');
            let data = {
                id: this.id,
                sn: this.sn,
            };
            if (this.SetCommission_type == 1) data.product_type = this.product_type;
            this.$axios.post(this.$api.serviceProvider[this.SetCommission_type == 1 ? 'TelCommission' : 'TelCommission2'], data).then(res => {
                if (res.code == 0) {
                    this.$message.success('处理成功');
                    this.showSetCommission = !1;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getList () {
            let searchForm = _.cloneDeep(this.searchForm);
            if (this.time) {
                searchForm.end_time = this.time[1] / 1000;
                searchForm.start_time = this.time[0] / 1000;
            }
            this.$axios.post(this.$api.serviceProvider.KuailonOrdersList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>